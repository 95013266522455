import React, { Component } from "react";
import axios from "axios";
import dateFormat from "dateformat";
import { Helmet } from "react-helmet";

import Spinner from "../components/spinner";

import "./home.scss";

export class Home extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      blogs: [],
      count: 0,
    };
  }

  async componentDidMount() {
    try {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/blogs/fetch/${sessionStorage.getItem("language")}/${
          this.state.count
        }`
      );

      if (data.success) this.setState({ loading: false, blogs: data.blogs });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async getBlogs(count) {
    this.setState({ loading: true });

    try {
      let { data } = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/api/blogs/fetch/${sessionStorage.getItem("language")}/${count}`
      );

      if (data.success) {
        for (let i = 0; i < data.blogs.length; i++) {
          this.setState((prevState) => ({
            blogs: [...prevState.blogs, data.blogs[i]],
            loading: false,
          }));
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className="home">
        <Helmet>
          <meta
            name="description"
            content="TezzFeed is a community of creators driven news & entertainment network. Our goal is to connect creators with the right audience and the audience with content they love more. "
          ></meta>
          <title>Tezzfeed</title>
        </Helmet>
        <div className="container">
          {this.state.blogs.map((blog, index) => (
            <div className="news-card my-4">
              <div className="row">
                <div className="col-xl-4 thumbnail-container">
                  <div
                    className="thumbnail"
                    style={{ backgroundImage: `url(${blog.thumbnail})` }}
                  ></div>
                </div>
                <div className="col-xl-8">
                  <div className="d-flex justify-content-between h-100 flex-column">
                    <div>
                      <h4
                        className="title"
                        onClick={() =>
                          (window.location.href = `/${blog.category[0]}/${blog.slug}`)
                        }
                      >
                        {blog.title}
                      </h4>
                      <p className="date">
                        {dateFormat(
                          blog.updatedAt,
                          "dddd, mmmm dS, yyyy, h:MM:ss TT"
                        )}
                      </p>
                      <p className="content">{blog.content}</p>
                    </div>

                    <p className="source">
                      read more at{" "}
                      <a
                        href={blog.sourceLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {blog.source}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {this.state.loading && this.state.blogs.length <= 0 ? null : (
            <div className="text-center">
              <button
                className="mt-3 mb-4"
                onClick={() => {
                  this.setState({ count: this.state.count + 1 }, () =>
                    this.getBlogs(this.state.count)
                  );
                }}
              >
                Load More
              </button>
            </div>
          )}
          {this.state.loading ? <Spinner></Spinner> : null}
          {/* {this.state.language === null ? (
            <div className="language">
              <h1 className="text-center">
                Please choose how you want to read TezzFeed
              </h1>
              <div className="d-flex align-items-center justify-content-center gap-5">
                <button
                  className="button-orange"
                  onClick={() => this.setLanguage("english")}
                >
                  English
                </button>
                <button
                  className="button-green"
                  onClick={() => this.setLanguage("bangla")}
                >
                  Bangla
                </button>
              </div>
            </div>
          ) : (
            <div>

            </div>
          )} */}
        </div>
      </div>
    );
  }
}

export default Home;
