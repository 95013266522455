import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const searchParams = useSearchParams()
    
    return (
      <Component
        params={params}
        navigate={navigate}
        query={searchParams}
        {...props}
        />
    );
  };
  
  return Wrapper;
};