import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/logo.png";
import logoBangla from "../assets/logo-bangla.png";

import "./navbar.scss";

export class Navbar extends Component {
  state = { language: sessionStorage.getItem("language") };

  render() {
    return (
      <div className="navigation">
        <nav className="nav">
          <div className="container-fluid mx-auto py-3">
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="icon"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <FontAwesomeIcon
                  icon={faBars}
                  size="2x"
                  style={{ color: "#181818", cursor: "pointer" }}
                ></FontAwesomeIcon>
              </div>
              <div className="text-center">
                {this.state.language === "english" ? (
                  <img
                    src={logo}
                    width="76px"
                    className="img-fluid"
                    alt="logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = "/")}
                  />
                ) : (
                  <img
                    src={logoBangla}
                    width="76px"
                    className="img-fluid"
                    alt="logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = "/")}
                  />
                )}
              </div>
              <div></div>
            </div>
          </div>
        </nav>

        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              Categories
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              style={{ color: "white !important" }}
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body">
            <ul className="sidebar-nav">
              <li>
                <a href="/opinion">Opinion</a>
              </li>
              <li>
                <a href="/sports">Sports</a>
              </li>
              <li>
                <a href="/business">Business</a>
              </li>
              <li>
                <a href="/entertainment">Entertainment</a>
              </li>
              <li>
                <a href="/life-living">Life & Living</a>
              </li>
              <li>
                <a href="/youth">Youth</a>
              </li>
              <li>
                <a href="/tech-startup">Tech & Startup</a>
              </li>
              <li>
                <a href="/movies-series">Movies & Series</a>
              </li>
              <li>
                <a href="/medical-news">Medical News</a>
              </li>
              <li>
                <a href="/tragedy-news">Tragedy News</a>
              </li>
              <li>
                <a href="/gaming-news">Gaming News</a>
              </li>
              <li>
                <a href="/multimedia">Multimedia</a>
              </li>
              <li>
                <a href="/finance">Finance</a>
              </li>
              <li>
                <a href="/feature-news">Feature News</a>
              </li>
              <li>
                <a href="/international-news">International News</a>
              </li>
              <li>
                <a href="/bangladeshi-news">Bangladeshi News</a>
              </li>
              <li>
                <a href="/breaking-news">Breaking News</a>
              </li>
              <li>
                <a href="/travel-living">Travel & Living</a>
              </li>
              <li>
                <a href="/earth">Earth</a>
              </li>
              <li>
                <a href="/weird-news">Weird News</a>
              </li>
              <li>
                <a href="/environment">Environment</a>
              </li>
              <li>
                <a href="/law-order">Law & Order</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
