import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./App.scss";

import Navbar from "./components/navbar";
import Footer from "./components/footer";

import Home from "./screens/home";
import Blogs from "./screens/blogs";

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      language: sessionStorage.getItem("language"),
    };
  }

  setLanguage(language) {
    sessionStorage.setItem("language", language);
    window.location.reload();
  }

  render() {
    return (
      <div
        className={
          (this.state.language === "english" ? "bg-orange" : "bg-green") +
          " App"
        }
      >
        <Helmet>
          <meta
            name="description"
            content="TezzFeed is a community of creators driven news & entertainment network. Our goal is to connect creators with the right audience and the audience with content they love more."
          ></meta>
          <title>Tezzfeed</title>
        </Helmet>
        <BrowserRouter>
          {this.state.language === null ? (
            <div className="language">
              <h1 className="text-center">
                Please choose how you want to read TezzFeed
              </h1>
              <div className="d-flex align-items-center justify-content-center gap-5">
                <button
                  className="button-orange"
                  onClick={() => this.setLanguage("english")}
                >
                  English
                </button>
                <button
                  className="button-green"
                  onClick={() => this.setLanguage("bangla")}
                >
                  Bangla
                </button>
              </div>
            </div>
          ) : (
            <>
              <Navbar></Navbar>
              <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route
                  exact
                  path="/:category/:slug?"
                  element={<Blogs />}
                ></Route>
              </Routes>
              <Footer></Footer>
            </>
          )}
        </BrowserRouter>
      </div>
    );
  }
}
